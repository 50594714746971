import { createSlice } from "@reduxjs/toolkit"


const initialState =  {
  token : null
}


const authSlice =  createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload
    },
   clear(state)  { 
    state.token = null
    }
  }
})

export const { setToken, clear } = authSlice.actions
export default authSlice.reducer
