// You can customize the template with the help of this file

//Template config options
import logo from '../assets/logo/logo.png'
const themeConfig = {
  app: {
    appName: <img src={logo} alt='logo' width={150} style={{ marginTop: '-10px' }}/>
    // appLogoImage: require('@src/assets/logo/logo_sm.svg').default
  },
  layout: {
    isRTL: false,
    skin: 'light',
    type: 'vertical',
    contentWidth: 'boxed',
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      type: 'floating', 
      backgroundColor: 'white'
    },
    footer: {
      type: 'static'
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
