import { createSlice } from "@reduxjs/toolkit"
import axios from '../../axios'


const initialState =  {
  user : null
}


const authSlice =  createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.user = action.payload
    },
   logout(state)  {
    const API =  async () => {
    const {data} =  await axios.get(`/dashboard/logout?id=${state.user?.user_id}`)

    if (data.success) {
      localStorage.removeItem("Token")
      localStorage.removeItem("userInfo")
      window.location.href = '/login'
      state.user = null
    }
    }   
    API()
      
    }
  }
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer
