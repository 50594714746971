import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    appointments: []
}

const appointmentSlice = createSlice({
    name: "appointment",
    initialState,
    reducers: {
        addAppointment(state, action) {
            state.appointments.push(action.payload)
        },

        cancelAppoinment(state, action) {
            const isExisit = state.appointments.findIndex((item) => item.id === Number(action.payload)) !== -1
            if (isExisit) {
                state.appointments = state.appointments.map((item) => {
                    if (item.id === Number(action.payload)) {
                        return {
                            ...item,
                           status:'cancel'
                        }
                    }
                    return item
                })
            } 
        },

        editAppoinment(state, action) {
            const isExisit = state.appointments.findIndex((item) => item.id === Number(action.payload.id)) !== -1

            if (isExisit) {
                state.appointments = state.appointments.map((item) => {
                    if (item.id === Number(action.payload.id)) {
                        return {
                            ...item,
                            treatment: action.payload.treatment,
                            appoinmentType: action.payload.appoinmentType,
                            doctor: action.payload.doctor,
                            doctorName: action.payload.doctorName,
                            patients: action.payload.patients,
                            patientName: action.payload.patientName,
                            patientsMobile: action.payload.patientsMobile,
                            appoinmentStart: action.payload.appoinmentStart,
                            appoinmentEnd: action.payload.appoinmentEnd,
                            status:action.payload.status
                        }
                    }
                    return item
                })
            } else {
                state.appointments.push(action.payload)
            }
        }


    }
})

export const { addAppointment, cancelAppoinment, editAppoinment } = appointmentSlice.actions
export default appointmentSlice.reducer
