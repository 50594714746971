// ** Reducers Imports
import auth from "./slices/auth"
import layout from "./slices/layout"
import navbar from "./slices/navbar"
import appoinments from "./slices/appoinments"
import token from "./slices/csrfToken"


const rootReducer = {
  auth,
  navbar,
  layout,
  appoinments,
  token
}

export default rootReducer
