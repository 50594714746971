import axios from "axios"

const instance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL 
  
  // baseURL:  `http://localhost:5000`

})

export default instance
